import * as React from 'react'
import * as styles from './box-with-icon.module.css'
import { Link } from 'gatsby'
import ArrowDown from '../../images/icons/arrow-right-long-1.svg'

const BoxWithIcon = ({ data, icon, title, className, link, children }) => {
    return (
        <div className={className}>
            <Link to={link}>
                <div className={styles.box}>
                    <img src={icon} alt={title} className='mx-auto' width={60} />
                    <div className={styles.title + ' mt-4'}>{title}</div>
                    <div className={styles.body}>{children}</div>
                    <ArrowDown className={'mx-auto ' + styles.arrowDown} />
                </div>
            </Link>
        </div>
    )
}

export default BoxWithIcon