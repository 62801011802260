import * as React from 'react'
import Seo from '../components/seo'
import Layout from '../components/layout/layout'
import BoxWithIcon from '../components/box-with-icon/box-with-icon'
import PageTitle from '../components/page-title/page-title'
import * as styles from '../styles/home.module.css'
import { graphql } from 'gatsby'

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <PageTitle
        superTitle='THIN EDGE LABS'
        title={<>Leading the way in <span>cutting-edge</span> <br />blockchain solutions</>}
        subTitle={<>We are able to realise the <strong>unthinkable</strong>, deliver the <strong>unexpected</strong> and enjoy the complete process.</>}
      />
      <div className={['row', styles.boxes].join(' ')}>
        <BoxWithIcon title='Explore projects' className='col-sm-6 col-md-4 mb-3' icon={data.wallet.publicURL} link='/products'>
          We're proud of our work and we're happy to share it with you.
        </BoxWithIcon>
        <BoxWithIcon title='Learn more about us' className='col-sm-6 col-md-4 mb-3' icon={data.list.publicURL} link='/about'>
          Get to know us better and find out what we're all about.
        </BoxWithIcon>
        <BoxWithIcon title='Get in touch with us' className='col-sm-6 col-md-4 mb-3' icon={data.paperPlane.publicURL} link='/contact'>
          Leave us a message and we'll get back to you as soon as possible.
        </BoxWithIcon>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    wallet: file(relativePath: {eq: "images/icons/wallet.svg"}) {
      publicURL
    },
    list: file(relativePath: {eq: "images/icons/list.svg"}) {
      publicURL
    },
    paperPlane: file(relativePath: {eq: "images/icons/paper-plane.svg"}) {
      publicURL
    }
  }
`

export const Head = ({ location }) => <Seo title="ThinEdgeLabs" locationPath={location.pathname} />
export default IndexPage